import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

function ParentsGuide() {
    return (
        <Layout>
            <Seo title="Parents Guide" />
            <section className="hero bg_img" data-background="/bg/hero.jpg">
                <div className="hero__shape">
                    <img src="/elements/hero/shape-2.png" alt="no surrender" />
                </div>
                <div className="el-1">
                    <img src="/elements/hero/el-1.png" alt="no surrender" />
                </div>
                <div className="el-2">
                    <img src="/elements/hero/el-2.png" alt="no surrender" />
                </div>
                <div className="el-3">
                    <img src="/elements/hero/el-3.png" alt="no surrender" />
                </div>
                <div className="el-4">
                    <img src="/elements/hero/el-4.png" alt="no surrender" />
                </div>
                <div className="el-5">
                    <img src="/elements/hero/el-5.png" alt="no surrender" />
                </div>
                <div className="el-6">
                    <img src="/elements/hero/el-6.png" alt="no surrender" />
                </div>
                <div className="el-7">
                    <img src="/elements/hero/el-7.png" alt="no surrender" />
                </div>
                <div className="el-8">
                    <img src="/elements/hero/el-8.png" alt="no surrender" />
                </div>
                <div className="el-9">
                    <img src="/elements/hero/el-9.png" alt="no surrender" />
                </div>
                <div className="el-10">
                    <img src="/elements/hero/el-10.png" alt="no surrender" />
                </div>
                <div className="el-11">
                    <img src="/elements/hero/el-11.png" alt="no surrender" />
                </div>
            </section>
            <section className="pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="hero__content d-flex flex-column align-items-center">
                                <h2
                                    className="hero__title wow fadeInUp text-center"
                                    data-wow-duration="0.5s"
                                    data-wow-delay="0.5s"
                                >
                                    PARENT'S GUIDE
                                </h2>
                                <div>
                                    <ul class="c11 lst-kix_g36y4kvkgssz-0 start">
                                        <li class="c18 li-bullet-0"><span class="c17"><a class="c1"
                                            href="https://nosurrender.studio/support">Where
                                            are NoSurrender games available?</a></span></li>
                                        <li class="c18 li-bullet-0"><span class="c17"><a class="c1"
                                            href="https://nosurrender.studio/support">What
                                            are the age limits for NoSurrender games?</a></span></li>
                                        <li class="c18 li-bullet-0"><span class="c17"><a class="c1"
                                            href="https://nosurrender.studio/support">Privacy
                                            and processing of personal data</a></span></li>
                                        <li class="c18 li-bullet-0"><span class="c17"><a class="c1"
                                            href="https://nosurrender.studio/support">How
                                            does chat work in NoSurrender games?</a></span></li>
                                        <li class="c18 li-bullet-0"><span class="c17"><a class="c1"
                                            href="https://nosurrender.studio/support">How
                                            to report an issue?</a></span></li>
                                        <li class="c18 li-bullet-0"><span class="c17"><a class="c1"
                                            href="https://nosurrender.studio/support">Are
                                            NoSurrender games free to play?</a></span></li>
                                        <li class="c18 li-bullet-0"><span class="c17"><a class="c1"
                                            href="https://nosurrender.studio/support">Can
                                            I manage or turn off in-app purchases?</a></span></li>
                                        <li class="c18 li-bullet-0"><span class="c17"><a class="c1"
                                            href="https://nosurrender.studio/support">Who
                                            processes in-app purchases?</a></span></li>
                                        <li class="c18 li-bullet-0"><span class="c17"><a class="c1"
                                            href="https://nosurrender.studio/support">Are
                                            my bank details kept private?</a></span></li>
                                        <li class="c18 li-bullet-0"><span class="c17"><a class="c1"
                                            href="https://nosurrender.studio/support">I&#39;ve
                                            made an in-game purchase, but I haven&#39;t received what I paid for. What now?</a></span></li>
                                        <li class="c18 li-bullet-0"><span class="c17"><a class="c1"
                                            href="https://nosurrender.studio/support">My
                                            child accidentally made an in-app purchase. Can I get a refund?</a></span></li>
                                        <li class="c18 li-bullet-0"><span class="c17"><a class="c1"
                                            href="https://nosurrender.studio/support">Where
                                            do I find my purchase history?</a></span></li>
                                        <li class="c18 li-bullet-0"><span class="c17"><a class="c1"
                                            href="https://nosurrender.studio/support">I&rsquo;ve
                                            seen another site that offers in-game products for NoSurrender games. Can I buy from
                                            them?</a></span></li>
                                        <li class="c18 li-bullet-0"><span class="c17"><a class="c1"
                                            href="https://nosurrender.studio/support">I&rsquo;ve
                                            received a bill for a purchase I didn&rsquo;t make. What should I do if I suspect fraudulent
                                            activity?</a></span></li>
                                        <li class="c18 li-bullet-0"><span class="c17"><a class="c1"
                                            href="https://nosurrender.studio/support">How
                                            can I contact NoSurrender?</a></span></li>
                                        <li class="c18 li-bullet-0"><span class="c17"><a class="c1"
                                            href="https://nosurrender.studio/support">Safety
                                            Checklist</a></span></li>
                                        <li class="c18 li-bullet-0"><span class="c17"><a class="c1"
                                            href="https://nosurrender.studio/support">Online
                                            Slang Guide</a></span></li>
                                    </ul>
                                    <p class="c7"><span class="c0 c26">&nbsp;</span></p>
                                    <p class="c3"><span class="c34 c19">In an effort to offer parents the best available information on safety in games,
                                        we have partnered with Internet Matters. Go visit our </span><span class="c19 c34"><a class="c1"
                                            href="https://www.internetmatters.org/">partner
                                            page</a></span><span class="c41 c34 c19">&nbsp;for a vast selection of informative resources on anything
                                                related to online safety.</span></p>
                                    <p class="c3"><span class="c0">Since you&rsquo;re here, most likely you, or someone you care about, have picked up
                                        one of our games in the name of fun. Thank you! We hope you enjoy them as much as we do making them.</span>
                                    </p>
                                    <p class="c3"><span class="c0">So; you probably have questions about keeping you, or your children safe while
                                        connected to our games through your mobile device. Things like age limits, in-app purchases, and privacy are
                                        all very valid concerns, and we&rsquo;re here to make sure that you get the support you need.</span></p>
                                    <p class="c3"><span class="c0">To make sure you can enjoy NoSurrender games in a safe and controlled environment, we
                                        stick to a clear set of principles that&rsquo;ve served our gamers and us well:</span></p>
                                    <ol class="c11 lst-kix_upat0mtzfrcl-0 start" start="1">
                                        <li class="c12 li-bullet-0"><span class="c0">We respect our gamers, and we put all our might into making the
                                            most fun games for them to enjoy. We&rsquo;ll never sacrifice game quality for any other short-term
                                            considerations.</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">We want you to be in total control of your experience with
                                            NoSurrender games. That means that before you or your child download our games, you should be given
                                            upfront information about the game&rsquo;s content. This means that it should be totally clear when
                                            you&rsquo;re about to make any purchases within a game.</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">If you or your child ever has any problems with our games, you
                                            should be able to quickly find out how to fix it. As well as providing guides like this, that means
                                            helping you to contact the right company for your issue, whether it&rsquo;s to do with payments (which
                                            will usually be Google or Apple, who process purchases and refunds) or the game itself (which will
                                            usually be us).</span></li>
                                    </ol>
                                    <p class="c3"><span class="c0">Read on as we try to cover what might be your most likely questions. If we&rsquo;ve
                                        missed anything, email us at community@nosurrender.studio. In your email, be sure to include any specific
                                        details that might be useful, like what happened and when, possible transaction ID receipts, relevant
                                        screenshots, etc.</span></p>
                                    <h3 class="c4" id="h.wskdfomjtdnd"><span class="c14">WHERE ARE NoSurrender GAMES AVAILABLE?</span></h3>
                                    <p class="c3"><span class="c0">Our games are globally available for free on iOS or Android phones and tablets. To
                                        start playing one of our games, the game&#39;s app has to be downloaded to the device via the Play Store
                                        (Android) or App Store (Apple). </span></p>
                                    <p class="c3 c27"><span class="c0"></span></p>
                                    <p class="c3"><span class="c14">WHAT ARE THE AGE LIMITS FOR NoSurrender GAMES?</span></p>
                                    <p class="c3"><span class="c0">Our games do not have general age limits. However, users of our games should observe
                                        that, under our Terms of Service, users under the legal age of majority in the country of their residence
                                        require their legal guardian&rsquo;s approval of our Terms of Service before registering their
                                        account.</span></p>
                                    <p class="c3"><span class="c19">Please note: When you download our games from e.g. Google Play or Apple&#39;s App
                                        Store you will find published age recommendations in the apps description. However, these age or maturity
                                        classifications refer only to the content suitability (similar to movie ratings G, PG or PG-13 in the U.S.,
                                        but related to gameplay, animations, etc.) according to the respective rating body responsible for such
                                        ratings. As these ratings are done independently by a third party without our contribution, they are not
                                        necessarily in line with our </span><span class="c19"><a class="c1"
                                            href="https://nosurrender.studio/terms-of-service">Terms
                                            of Service</a></span><span class="c0">.</span></p>
                                    <p class="c3"><span class="c19">For more info on how these categories are defined, visit these support websites for
                                    </span><span class="c19"><a class="c1"
                                        href="#">Apple</a></span><span
                                            class="c19">&nbsp;and </span><span class="c19"><a class="c1"
                                                href="#">Google</a></span><span
                                                    class="c0">.</span></p>
                                    <h3 class="c4" id="h.pp68btqs1g9"><span class="c14">PRIVACY AND PROCESSING OF PERSONAL DATA</span></h3>
                                    <p class="c3"><span class="c0">USERNAME</span></p>
                                    <p class="c3"><span class="c0">Our players are asked to set up a username during the game&#39;s tutorial. We
                                        recommend that our players choose a nickname not directly identifiable to the players real-life person to
                                        increase security and privacy. You are able to change the username through the in-game settings (open the
                                        game, tap on the gear-wheel icon and look for &quot;change name&quot;).</span></p>
                                    <p class="c3"><span class="c0">PAYMENT INFORMATION</span></p>
                                    <p class="c3"><span class="c0">We do not store any credit card information related to in-game purchases as the
                                        payment transactions are completed through Apple&#39;s App Store or Google Play (depending on your device)
                                        via the personal email address associated with that account.</span></p>
                                    <p class="c3"><span class="c0">MARKETING</span></p>
                                    <p class="c3"><span class="c0">When contacting our support through the in-game chat, you&#39;ll have the option to
                                        share your email address with us. We&#39;ll always ask for your consent when sending direct marketing
                                        messages to your mobile device. We won&#39;t sell or disclose your personal data to third parties for their
                                        own commercial purposes.</span></p>
                                    <p class="c3"><span class="c19">Please find out more about our privacy and data processing practices from our
                                    </span><span class="c19"><a class="c1"
                                        href="https://nosurrender.studio/privacy-policy">Privacy
                                        Policy</a></span><span class="c0">.</span></p>
                                    <h3 class="c4" id="h.wim5j1yag6xt"><span class="c14">HOW DOES CHAT WORK IN NoSurrender GAMES?</span></h3>
                                    <p class="c3"><span class="c0">All NoSurrender games have an in-game chat functionality. Many of our players enjoy
                                        interacting with one another through chat in our games, and we work hard to maintain a safe and secure
                                        environment for that.</span></p>
                                    <p class="c7"><span class="c0">TEAM CHAT</span></p>
                                    <p class="c3"><span class="c0">In all our games, players can talk to other players by joining a team (called Task
                                        Force, Clan, Band or Neighbourhood, depending on the game). Once you join a team, you have access to the
                                        team chat functionality and can talk to the members of your team. Teams can be closed, invite-only or open
                                        to everyone.</span></p>
                                    <p class="c7"><span class="c0">GLOBAL CHAT</span></p>
                                    <p class="c3"><span class="c0">In Clash of Clans, users have access to a public chat room with other players from
                                        around the world. This is called global chat or just &quot;global&quot;.</span></p>
                                    <p class="c3"><span class="c0">Although NoSurrender Chat only allows text messaging, it is still worth reminding
                                        young people about their own digital footprint. Once shared, messages can be copied, re-shared and posted
                                        anywhere online.</span></p>
                                    <p class="c7"><span class="c0">SAFETY FEATURES</span></p>
                                    <p class="c3"><span class="c0">We display regular warnings against sharing personal information. If issues arise,
                                        our games contain easy to access tools for players to report any offensive, insulting or inappropriate
                                        behaviour to us. In global chat users have the option to mute players whose messages they do not want to
                                        see. This means that they will not see messages posted in chat by this user.</span></p>
                                    <p class="c3"><span class="c0">All team chats also have a profanity filter. It&#39;s a good idea to advise your
                                        child that if they are chatting with someone they don&#39;t know and are uncomfortable with, they should
                                        exit the team/game and speak to you about it. Users can leave a team whenever they want to. Furthermore a
                                        report functionality is directly accessible from within the chat. Read more about that in the next
                                        section.</span></p>
                                    <h3 class="c4" id="h.mq23dhg2fwgr"><span class="c14">HOW TO REPORT AN ISSUE?</span></h3>
                                    <p class="c3"><span class="c0">If you or your child come across a user that is not following NoSurrender&#39;s Safe
                                        and Fair Play Policy, report them to us in a few simple steps.</span></p>
                                    <p class="c7"><span class="c0">Open the game&#39;s chat</span></p>
                                    <p class="c7"><span class="c0">Find the message / player you wish to report</span></p>
                                    <p class="c7"><span class="c0">Tap the message and choose &quot;Report&quot;</span></p>
                                    <p class="c3"><span class="c0">The reporter&#39;s identity is not shared with the person being reported. Using the
                                        Report button does not automatically end up in a ban for the reported user. However, every report is
                                        manually reviewed by our moderators and taken seriously. Due to the amount of reports we receive, it may
                                        take up to 48h until a report is reviewed.</span></p>
                                    <p class="c3"><span class="c0">If you need to report any concerns by email, reach us at community@nosurrender.studio
                                        - including specific details about the player in question (e.g. screenshots of the conversation and or
                                        player profile), to help us quickly understand and identify the issue.</span></p>
                                    <h3 class="c4" id="h.y0t7nyzdjv46"><span class="c14">ARE NoSurrender GAMES FREE TO PLAY?</span></h3>
                                    <p class="c3"><span class="c0">All NoSurrender games are free to download, offering optional &quot;in-app
                                        purchases&quot;. In-app purchases are never required to play the game, but can be used to enhance certain
                                        gameplay elements. In-app purchases vary in price, ranging from $0.99 to $999.99 (without taxes) in U.S. app
                                        stores, for example. In-app purchases are paid for with real money, and are always clearly labeled as such.
                                        They are accessible &quot;in game&quot;, meaning that the options to make a purchase are found within the
                                        game itself.</span></p>
                                    <h3 class="c4" id="h.k00gj44upzsj"><span class="c14">CAN I MANAGE OR TURN OFF IN-APP PURCHASES?</span></h3>
                                    <p class="c3"><span class="c0">As long as no payment information (e.g. credit card information) is added to your
                                        child&#39;s device, no purchases can be made. Should you decide to make purchases for your child and for
                                        this reason add your payment information to the device, remember to adjust password protection settings, or
                                        disable in-app purchases entirely afterwards to avoid unauthorised purchases.</span></p>
                                    <p class="c3"><span class="c0">In-app purchase settings are different depending on what sort of device you are
                                        using. Find the instructions you need linked below:</span></p>
                                    <p class="c7"><span class="c19">Instructions for </span><span class="c19"><a class="c1"
                                        href="#">Apple
                                        iOS devices (like iPhone or iPad)</a></span></p>
                                    <p class="c7"><span class="c19">Instructions for </span><span class="c19"><a class="c1"
                                        href="#">Google
                                        Android devices (like Samsung Galaxy, OnePlus, HTC, Nexus, etc)</a></span></p>
                                    <h3 class="c4" id="h.kooglouijhgt"><span class="c14">WHO PROCESSES IN-APP PURCHASES?</span></h3>
                                    <p class="c3"><span class="c0">NoSurrender does not process payments for in-app purchases, or have access to credit
                                        card information. Payment transactions themselves are processed through the App Store or Google Play
                                        (depending on your device) via the personal email address associated with that account. Transaction receipts
                                        are emailed to you by Apple or Google after each purchase. Sometimes, purchases may be bundled together if
                                        you&#39;ve bought multiple items, and it might take a few days for you to receive the receipt. These
                                        post-purchase processes are subject to change by Apple or Google. NoSurrender doesn&#39;t offer any billing
                                        programs.</span></p>
                                    <h3 class="c4" id="h.lguvwfnhdvhm"><span class="c14">ARE MY BANK DETAILS KEPT PRIVATE?</span></h3>
                                    <p class="c3"><span class="c0">We don&#39;t process payments for in-app purchases, nor do we have access to any of
                                        your payment information. That&#39;s because the payment transactions themselves are completed through
                                        Apple&#39;s App Store or Google Play (depending on your device) via your personal email address associated
                                        with that account. Receipts for these transactions are emailed to you by Apple or Google after each purchase
                                        (though please note that any post-purchase processes are controlled by Apple or Google and are subject to
                                        change by them).</span></p>
                                    <h3 class="c4" id="h.v6d3p44do4d1"><span class="c14">I&#39;VE MADE AN IN-GAME PURCHASE, BUT I HAVEN&#39;T RECEIVED
                                        WHAT I PAID FOR. WHAT NOW?</span></h3>
                                    <p class="c3"><span class="c0">Usually transactions are instant. However in rare cases it can take up to 48 hours
                                        for transactions to process through Apple or Google and then make it into your game. It is also recommended
                                        to restart the app by fully closing and re-opening it. Sometimes a fresh connection to the servers can
                                        trigger the in-app currency to appear.</span></p>
                                    <p class="c3"><span class="c19">If it&#39;s been 48 hours and still nothing has happened, please contact
                                    </span><span class="c19"><a class="c1"
                                        href="#">Apple</a></span><span
                                            class="c19">&nbsp;or </span><span class="c19"><a class="c1"
                                                href="#">Google</a></span><span
                                                    class="c0">&nbsp;directly for more information.</span></p>
                                    <h3 class="c4" id="h.t4vwg6movkfe"><span class="c14">MY CHILD ACCIDENTALLY MADE AN IN-APP PURCHASE. CAN I GET A
                                        REFUND?</span></h3>
                                    <p class="c3"><span class="c0">As with most downloadable software products, items bought in our games (in-app
                                        purchases) are non-refundable. In rare cases, exceptions can be applied.</span></p>
                                    <p class="c3"><span class="c0">If the purchase was made on an Apple (iOS) device:</span></p>
                                    <p class="c3"><span class="c19">For purchases made on an iOS device, NoSurrender is not able to handle refunds
                                        directly. Please visit </span><span class="c19"><a class="c1"
                                            href="#">Apple
                                            support</a></span><span class="c0">&nbsp;and select the &quot;Contact iTunes store support&quot; link,
                                                then &quot;Purchases, billing and redemption&quot; and choose the option that fits you best.</span></p>
                                    <p class="c3"><span class="c0">If the purchase was made on a Google (Android) device:</span></p>
                                    <p class="c3"><span class="c0">Please send an email to community@nosurrender.studio or contact us directly through
                                        in-game support by going to Settings &gt; Help &amp; Support and then tap &quot;Contact Us&quot; in the
                                        upper right hand corner. In your message please state the name of the game the purchase was made in and add
                                        the receipt of the purchase. The transaction ID (looks similar to this - GPA.1234-1234-1234-12345) at the
                                        bottom of the document must be visible. Receipts for transactions have been emailed to you by Google after
                                        each purchase.</span></p>
                                    <p class="c3"><span class="c0">Please note: Receipts for any transactions are emailed to you by Apple or Google soon
                                        after each purchase. However, purchases may be bundled together if you&#39;ve bought multiple items and
                                        might take longer. These post-purchase processes are subject to change by Apple or Google. NoSurrender
                                        doesn&#39;t offer any billing programs.</span></p>
                                    <h3 class="c4" id="h.1z002c4qsrm0"><span class="c14">WHERE DO I FIND MY PURCHASE HISTORY?</span></h3>
                                    <p class="c3"><span class="c0">To review your purchase history, please check the guides below:</span></p>
                                    <p class="c7"><span class="c0">AndroidEnter payments.google.com, then log in with your desired Google account</span>
                                    </p>
                                    <p class="c7"><span class="c0">Select &quot;Transactions&quot; to display your Play Store order history.</span></p>
                                    <p class="c7"><span class="c0">Choose an individual entry to view its corresponding receipt.</span></p>
                                    <p class="c7"><span class="c0">iOSOpen iTunes on your computer.</span></p>
                                    <p class="c7"><span class="c0">Select your username (this should be your e-mail address), and choose
                                        &quot;Account&quot;</span></p>
                                    <p class="c7"><span class="c0">Scroll down to &quot;Purchase History&quot;, and choose &quot;See All&quot;</span>
                                    </p>
                                    <h3 class="c4" id="h.rb4csj4ubvyk"><span class="c14">I&rsquo;VE SEEN ANOTHER SITE THAT OFFERS IN-GAME PRODUCTS FOR
                                        NoSurrender GAMES. CAN I BUY FROM THEM?</span></h3>
                                    <p class="c3"><span class="c19">If you want to make an in-app purchase, the only way is to buy through the game
                                        itself on your mobile device. Beware of any third party sites that promise you enhancements for our games
                                        (like &ldquo;free gem&rdquo; websites, etc). These sites may collect your personal information, money, or
                                        both, and often never deliver the promised in-game products. Selling, redeeming or trading virtual in-game
                                        currency is otherwise not allowed by our </span><span class="c19"><a class="c1"
                                            href="https://nosurrender.studio/terms-of-service">Terms
                                            of Service</a></span><span class="c0">.</span></p>
                                    <p class="c3"><span class="c0">IMPORTANT: As with anything related to the Internet, always keep your passwords to
                                        yourself and consider changing them on a regular basis. NoSurrender will never ask you for your game account
                                        password(s) or credit card information.</span></p>
                                    <h3 class="c4" id="h.e0qpwonau7xm"><span class="c14">I&rsquo;VE RECEIVED A BILL FOR A PURCHASE I DIDN&rsquo;T MAKE.
                                        WHAT SHOULD I DO IF I SUSPECT FRAUDULENT ACTIVITY?</span></h3>
                                    <p class="c3"><span class="c0">If neither you nor anyone else who has access to your device have played one of our
                                        games, but you have been billed for a purchase(s) made within our games, it is likely that your Google or
                                        Apple account was compromised. If so, immediately contact Apple or Google by following the instructions
                                        below:</span></p>
                                    <p class="c3"><span class="c0">For Apple (iOS) devices:</span></p>
                                    <p class="c3"><span class="c19">Visit </span><span class="c19"><a class="c1"
                                        href="#">Apple
                                        support</a></span><span class="c0">&nbsp;and select the &quot;Contact iTunes store support&quot; link,
                                            then &quot;Purchases, billing and redemption&quot; and choose the option that fits you best.</span></p>
                                    <p class="c3"><span class="c0">For Google (Android) devices:</span></p>
                                    <p class="c3"><span class="c0">Please send an email to community@nosurrender.studio or contact us directly through
                                        in-game support by going to Settings &gt; Help &amp; Support and then tap &quot;Contact Us&quot; in the
                                        upper right hand corner. In your message please state the name of the game the purchase was made in and add
                                        the receipt of the purchase. The transaction ID (looks similar to this - GPA.1234-1234-1234-12345) at the
                                        bottom of the document must be visible. Receipts for transactions have been emailed to you by Google after
                                        each purchase..</span></p>
                                    <p class="c3"><span class="c0">If you&rsquo;ve already contacted Apple or Google about suspected fraudulent activity
                                        and they&rsquo;ve sent you to us, please contact us at transactions@nosurrender.studio. Be sure to include
                                        specific details like what happened and when, transaction ID receipts, relevant screenshots, etc.</span></p>
                                    <h3 class="c4" id="h.f4ctxhtkzrd8"><span class="c14">HOW CAN I CONTACT NoSurrender?</span></h3>
                                    <p class="c3"><span class="c0">Like we said, we&rsquo;re here to help! If you have any more questions, there are
                                        several ways you can contact us!</span></p>
                                    <p class="c7"><span class="c0">CAN I CALL NoSurrender?</span></p>
                                    <p class="c3"><span class="c0">We don&#39;t offer support via phone but we are easy to reach either directly from
                                        within the game or via email. Our well trained support staff is happy to answer your questions 24/7 in the
                                        following languages: English, Turkish.</span></p>
                                    <p class="c3"><span class="c0">HOW TO CONTACT US DIRECTLY FROM WITHIN THE GAME</span></p>
                                    <p class="c7"><span class="c0">Open the game</span></p>
                                    <p class="c7"><span class="c0">Open settings (tap on the gear-wheel icon)</span></p>
                                    <p class="c7"><span class="c0">Tap on &quot;Help &amp; Support&quot;</span></p>
                                    <p class="c7"><span class="c0">Tap on &quot;Contact Us&quot; in the upper right corner</span></p>
                                    <p class="c7"><span class="c0">OR in case that option is not givenOpen any given FAQ</span></p>
                                    <p class="c7"><span class="c0">Scroll down</span></p>
                                    <p class="c7"><span class="c0">Tap &quot;No&quot; where it says &quot;Was this helpful&quot;</span></p>
                                    <p class="c7"><span class="c0">Tap &quot;Contact Us&quot;</span></p>
                                    <p class="c3"><span class="c0">CONTACT VIA EMAIL</span></p>
                                    <p class="c3"><span class="c0">You can send us an email at community@nosurrender.studio. In your email, be sure to
                                        include any specific details that might be useful, like what happened and when, possible transaction ID
                                        receipts, relevant screenshots, etc.</span></p>
                                    <p class="c3"><span class="c0">WHERE IS NoSurrender LOCATED?</span></p>
                                    <p class="c3"><span class="c0">NoSurrender&#39;s Headquarter is in &#304;STANBUL, TURKEY. </span></p>
                                    <p class="c3 c27"><span class="c0"></span></p>
                                    <p class="c3"><span class="c14">SAFETY CHECKLIST</span></p>
                                    <p class="c3"><span class="c0">NoSurrender&#39;s games are made to be enjoyed. They can be a great way to destress
                                        and take a break. If you feel as though your child isn&#39;t having much fun with the games, then don&#39;t
                                        be afraid to speak to them about it. Below is a list of things that are worth discussing with your
                                        child.</span></p>
                                    <ol class="c11 lst-kix_l9sp13rp5fmd-0 start" start="1">
                                        <li class="c12 li-bullet-0"><span class="c0">HAVE A CONVERSATION WITH YOUR CHILD ABOUT GAMING</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Talk about your child&#39;s gaming habits. Encourage them to speak
                                            to you about anything that upsets them.FAMILIARIZE YOURSELF WITH THE GAME AND ITS REPORTING
                                            OPTIONS</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">We recommend showing interest and even playing the game with your
                                            child at regular intervals. Helpful hint: go through in-game tutorials and game guides together in order
                                            to make sure you both understand how the game works. In particular, you and your child should know how
                                            to use the mute and report tool.CHECK YOUR CHILD&#39;S USERNAME</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Make sure your child&#39;s in-game nickname is not their real name.
                                            You might also want to check if their nickname is inappropriate.CHECK IN-APP PURCHASE SETTINGS</span>
                                        </li>
                                        <li class="c12 li-bullet-0"><span class="c0">Get familiar with ways to restrict or turn off in-app purchases on
                                            your child&#39;s device.TALK WITH YOUR CHILD ABOUT THE RISKS OF SHARING INFORMATION ONLINE</span></li>
                                        <li class="c12 li-bullet-0"><span class="c0">Carefully discuss the dangers of talking to strangers online. They
                                            should develop a healthy skepticism of who they&#39;re talking to and about what. Key things they should
                                            NOT share include: gender, age, location, ethnicity, religion, sexuality, height, hobbiesREPORT / GET
                                            HELP</span></li>
                                    </ol>
                                    <p class="c7"><span class="c0">If all things fail and your child runs into in-game problems, report it so we can
                                        help.</span></p>
                                    <h3 class="c4" id="h.jpjjppkcs426"><span class="c14">ONLINE SLANG GUIDE</span></h3>
                                    <p class="c3"><span class="c0">A unique new language has emerged online, especially in games. Below are some
                                        commonly used terms, in addition to what they mean. This might come in handy when having a conversation with
                                        your child about gaming.</span></p><a id="t.de564fde170f4df3f2f236355bf661dbfcf2a1c7"></a><a id="t.0"></a>
                                    <table class="c39">
                                        <tbody>
                                            <tr class="c37">
                                                <td class="c42" colspan="1" rowspan="1">
                                                    <p class="c8"><span class="c2 c5">Word</span></p>
                                                </td>
                                                <td class="c43" colspan="1" rowspan="1">
                                                    <p class="c8"><span class="c2 c5">Meaning</span></p>
                                                </td>
                                            </tr>
                                            <tr class="c33">
                                                <td class="c36" colspan="1" rowspan="1">
                                                    <p class="c8"><span class="c2">Noob, n00b</span></p>
                                                </td>
                                                <td class="c28" colspan="1" rowspan="1">
                                                    <p class="c8"><span class="c2">Someone who is new to the game, or acts as though they are new to the
                                                        game</span></p>
                                                </td>
                                            </tr>
                                            <tr class="c22">
                                                <td class="c21" colspan="1" rowspan="1">
                                                    <p class="c8"><span class="c2">Scrub</span></p>
                                                </td>
                                                <td class="c23" colspan="1" rowspan="1">
                                                    <p class="c8"><span class="c2">An unskilled player</span></p>
                                                </td>
                                            </tr>
                                            <tr class="c22">
                                                <td class="c21" colspan="1" rowspan="1">
                                                    <p class="c8"><span class="c2">Troll</span></p>
                                                </td>
                                                <td class="c23" colspan="1" rowspan="1">
                                                    <p class="c8"><span class="c2">People who taunt, tease or bait other players for a reaction</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr class="c22">
                                                <td class="c21" colspan="1" rowspan="1">
                                                    <p class="c8"><span class="c2">Spamming</span></p>
                                                </td>
                                                <td class="c23" colspan="1" rowspan="1">
                                                    <p class="c8"><span class="c2">When someone repeatedly sends messages or requests</span></p>
                                                </td>
                                            </tr>
                                            <tr class="c22">
                                                <td class="c21" colspan="1" rowspan="1">
                                                    <p class="c8"><span class="c2">BM&#39;ing</span></p>
                                                </td>
                                                <td class="c23" colspan="1" rowspan="1">
                                                    <p class="c8"><span class="c2">Short for &quot;Bad Mannering&quot; - usually involves taunting or
                                                        poor sportsmanship</span></p>
                                                </td>
                                            </tr>
                                            <tr class="c22">
                                                <td class="c21" colspan="1" rowspan="1">
                                                    <p class="c8"><span class="c2">Doxxing</span></p>
                                                </td>
                                                <td class="c23" colspan="1" rowspan="1">
                                                    <p class="c8"><span class="c2">Broadcasting someone&#39;s personal information against their
                                                        will</span></p>
                                                </td>
                                            </tr>
                                            <tr class="c22">
                                                <td class="c21" colspan="1" rowspan="1">
                                                    <p class="c8"><span class="c2">SWATing</span></p>
                                                </td>
                                                <td class="c23" colspan="1" rowspan="1">
                                                    <p class="c8"><span class="c2">Sending police to someone&#39;s home address</span></p>
                                                </td>
                                            </tr>
                                            <tr class="c6">
                                                <td class="c21" colspan="1" rowspan="1">
                                                    <p class="c8"><span class="c2">pwnd / pwn3d</span></p>
                                                </td>
                                                <td class="c23" colspan="1" rowspan="1">
                                                    <p class="c8"><span class="c2">Synonym for &quot;Owned&quot; - term is used when authoritatively
                                                        outplaying someone else</span></p>
                                                </td>
                                            </tr>
                                            <tr class="c6">
                                                <td class="c21" colspan="1" rowspan="1">
                                                    <p class="c8"><span class="c2">Griefer</span></p>
                                                </td>
                                                <td class="c23" colspan="1" rowspan="1">
                                                    <p class="c8"><span class="c2">Similar to troll, someone who ruins a players battle or undermines
                                                        their effort in the game, causing grief</span></p>
                                                </td>
                                            </tr>
                                            <tr class="c6">
                                                <td class="c21" colspan="1" rowspan="1">
                                                    <p class="c8"><span class="c2">Grooming</span></p>
                                                </td>
                                                <td class="c23" colspan="1" rowspan="1">
                                                    <p class="c8"><span class="c2">Befriending and establishing an emotional connection with a child to
                                                        lower the child&#39;s inhibitions with the object of sexual abuse</span></p>
                                                </td>
                                            </tr>
                                            <tr class="c22">
                                                <td class="c21" colspan="1" rowspan="1">
                                                    <p class="c8"><span class="c2">Other</span></p>
                                                </td>
                                                <td class="c23" colspan="1" rowspan="1">
                                                    <p class="c8"><span class="c2"><a class="c1"
                                                        href="#">Online
                                                        Slang Guide</a></span></p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p class="c3"><span class="c0">Thanks for your help in making our games as safe and as fun as possible!</span></p>
                                    <p class="c3"><span class="c19">- </span><span class="c34 c19 c41">Your friends at NoSurrender</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default ParentsGuide
